import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import copy from "../../images/copy.png";
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import goarrow from "../../images/go-arrow.png";
import alternate from "../../images/alternate.jpg";
import React, { useState, useEffect } from 'react';
import modalcloseicon from "../../images/close.png";
import { Link, useNavigate } from 'react-router-dom';
import tickcircle from "../../images/tickcircle.png";
import infocircle from "../../images/infocircle.svg";
import explorenft from "../../images/explorenft.png";
import nationmonkey from "../../images/nation-monkey.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import mintstepinfo from "../../images/mint-step-info.png";
import microeconomylogo from "../../images/microeconomy-icon.png";
import collectionimagestepinfo from "../../images/collection-image-step-info.png";
import { setLoader, getXRC721, getXRC20, setBalance, setGrootBalance, getKybStatus, getKycStatus } from "../../store/actions/Auth";
import { networkId, XRC721ABI, XRC20ABI, CIFI, CiFiAccessNFTAddress, CiFiAccessNFTABI, AdminWhitelistAddress, AdminWhitelistABI } from "../../store/config";


const Nftdrops = (props, { mode }) => {

  const [txHash, setTxHash] = useState("");
  const [from, setFrom] = useState("");
  const [collections, setCollections] = useState([]);
  const [data, setData] = useState({});
  let [count, setCount] = useState(0);
  let [minted, setMinted] = useState(0);
  let [price, setPrice] = useState(0);

  let [isNFT, setIsNFT] = useState();
  let [isWhitelist, setIsWhitelist] = useState(false);

  const [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);
  const [microeconomyMintModal, setMicroeconomyMintModal] = useState(false);
  const [microeconomySuccessModal, setMicroeconomySuccessModal] = useState(false);
  const [stepModal1, setStepModal1] = useState(true);
  const [stepModal2, setStepModal2] = useState(false);

  const [model, setModel] = useState(true);

  const navigate = useNavigate()

  setTimeout(() => {
    const modalRoot = document.querySelector('.react-responsive-modal-root');

    if (modalRoot) {
      //Add a custom class to the root element
      const stepsModal = modalRoot.querySelector('.steps-modal');

      if (stepsModal) {
        // Perform your action here
        modalRoot.classList.add('custom-modal-root');
      }

    } else {
      console.error("Element not found.");
    }
  }, 1000);

  const getNFTs = async () => {
    if (CiFiAccessNFTABI && CiFiAccessNFTAddress) {
      props.setLoader({ message: "Load Microeconomy...", status: true });
      let newCiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);
      if (newCiFiAccessNFTContract) {
        let tokenBalance = await newCiFiAccessNFTContract.methods.balanceOf(props.publicAddress).call({ from: props.publicAddress });
        // tokenBalance =  1; //tesing purpose
        setIsNFT(tokenBalance);
        if (Number(tokenBalance) >= 1) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Load Microeconomy...", status: false });
      }
    }

    if (AdminWhitelistABI && AdminWhitelistAddress) {
      props.setLoader({ message: "Loading...", status: true });
      let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
      if (newAdminWhitelist) {
        let status = await newAdminWhitelist.methods.isWhitelist(props.publicAddress).call({ from: props.publicAddress });
        // status =  true; //tesing purpose
        setIsWhitelist(status);
        if (status == true) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Loading...", status: false });
      }
    }

  }

  useEffect(() => {
    if (props.publicAddress && web3) {
      getNFTs();
    }
  }, [props.publicAddress, web3]);

  useEffect(() => {
    props.getXRC20();
    props.getXRC721();
    if (props.publicAddress == null || props.publicAddress == undefined) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    } else {
      let address = props.publicAddress;
      let output = props.publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        address = "0x" + props.publicAddress.substring(3);
        setFrom(address);
      } else {
        address = props.publicAddress;
        setFrom(address);
      }
    }
  }, []);

  async function setMarketplaceData() {
    const updatedCollection = await Promise.all(
      props.xrc721Collections.map(async (item) => {
        if (parseInt(item['item']['network']) == parseInt(props.selectDefaultNetwok)) {
          let tokenSymbol = "XDC";
          if (item['item']['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
            let contract = new web3.eth.Contract(XRC20ABI, item['item']['paymentToken']);
            let symbol = await contract.methods.symbol().call();
            tokenSymbol = symbol;
          }
          return { ...item, tokenSymbol };
        }
        return item;
      })
    );
    setCollections(updatedCollection);
  }

  useEffect(() => {
    if (props.publicAddress == null || props.publicAddress == undefined) {
      return;
    } else {
      if (props.xrc721Collections.length > 0 && web3) {
        setMarketplaceData();
      }
    }
  }, [props.xrc721Collections, props.selectDefaultNetwok, props.publicAddress, web3]);

  function incrementCount() {
    if (count >= (data['supply'] - minted)) {
      EventBus.publish("error", `Mint amount exceeds available NFTs!`);
      return;
    }
    count = count + 1;
    setCount(count);
  }

  function decrementCount() {
    if (count > 0) {
      count = count - 1;
      setCount(count);
    } else {
      setCount(0);
    }
  }

  async function setInstance(item) {
    setMicroeconomyMintModal(true);
    setData(item);
    let contract = new web3.eth.Contract(XRC721ABI, item['address']);
    let mint = await contract.methods.totalSupply().call();
    setMinted(mint);
    setPrice(item['price']);
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function mintNFT(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;


      if (count >= (data['supply'] - minted)) {
        EventBus.publish("error", `Mint amount exceeds available NFTs!`);
        return;
      }

      if (count == 0) {
        EventBus.publish("error", `Enter NFT amount to mint!`);
        return;
      }

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (props.kycStatus !== "adminApproved") {
        EventBus.publish("error", `Submit KYC verification to access this function!`);
        return;
      }

      // if (props.userSoulBound == "") {
      //   EventBus.publish("error", `User soul bound token not available!`);
      //   return;
      // }

      let deployer = (await web3.currentProvider.enable())[0];


      const balanceWei = await web3.eth.getBalance(deployer);
      console.log("*** balanceWei", balanceWei);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let tokenAmount = 0;

      let sentValue = (parseInt(data['price']) * parseInt(count));
      sentValue = web3.utils.toWei(sentValue.toString(), "ether");

      let price = data['price'];
      // let price = web3.utils.toWei(data['price'].toString(), "ether");

      console.log("*** price", price);

      if (data['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
        let contractPaymentToken = new web3.eth.Contract(XRC20ABI, data['paymentToken']);
        let userBalance = await contractPaymentToken.methods.balanceOf(deployer).call();
        userBalance = web3.utils.fromWei(userBalance.toString(), "ether");


        if (parseInt(userBalance) < (parseInt(price) * parseInt(count))) return EventBus.publish("error", `Insufficient token amount`);
        props.setLoader({
          message: "Approval in Progress...",
          status: true,
        });

        tokenAmount = parseInt(price) * parseInt(count);
        tokenAmount = web3.utils.toWei(tokenAmount.toString(), "ether");

        console.log("*** tokenAmount",tokenAmount);

        await contractPaymentToken.methods.approve(data['address'], tokenAmount.toString()).send({
          from: deployer,
        });
        props.setLoader({
          message: "Approval complete...",
          status: true,
        });
        sentValue = 0;
      }

      props.setLoader({
        message: "Minting in Progress...",
        status: true,
      });

      let from = publicAddress;
      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let contract = new web3.eth.Contract(XRC721ABI, data['address']);

      console.log("*** sentValue", sentValue);

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: sentValue,
          to: data['address'],
          gas: 5000000,
          data: contract.methods
            .mint(from, count, tokenAmount.toString(), props.userSoulBound)
            .encodeABI(),
        })
        .on('transactionHash', hash => {
          console.log(`************** deploy contract hash = ${hash}`);
          setTxHash(hash);
        })
        .on('receipt', async receipt => {
          await refreshBalance();
          setCount(0);
          props.setLoader({ status: false });
          EventBus.publish("success", `NFT(s) Minted Successfully!`);
          setMicroeconomyMintModal(false);
          setMicroeconomySuccessModal(true);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Mint Not Completed...",
        status: false,
      });
      setCount(0);
      setMicroeconomyMintModal(false);
      EventBus.publish("error", `Mint Failed`);
    }
  };

  async function copiedAddress() {
    EventBus.publish("success", "Transaction Hash Copied");
  }

  const handleCopyText = () => {
    navigator.clipboard.writeText(txHash)
      .then(() => EventBus.publish("success", "Transaction Hash Copied"))
      .catch((error) => console.log('Copy failed:', error));
  };

  async function callFilter(value) {
    let filterValue = props.xrc721Collections.filter((a) => {
      if (value) {
        return a.item.name?.toString().toLowerCase().includes(value.toLowerCase())
      }
      else {
        return a
      }
    });
    setCollections(filterValue);
  }

  console.log("*** props.userSoul", props.userSoulBound);
  console.log("*** data",data);

  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={microeconomylogo} alt="" />
          <span>Micro Economy</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
        <ConnectWallet />
      </div>

      <div className="nft-collection">
        <div className="head">
          <h2>Explore NFT Collections<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>
          <div className="collection-filters ">
            <span className="mb-1">Search Collections : </span>
            <div className="filters">
              <div className="search-field">
                <input type="search" placeholder="Search NFT Collection..." onChange={e => callFilter(e.target.value)} />
              </div>
            </div>
          </div>
        </div>

        <div className="collection-wrap">
          {
            collections?.length > 0 &&
            collections?.map((item) => (
              <div className="box">
                <Link className="" to={`/dashboard/microeconomy/explore-nft/${item['item']['address']}`}>
                  <img src={item['item']['banner']} alt=" " />
                </Link>

                <div className="content">
                  <div className="info">
                    <div className="profile-info">
                      <img src={item['ownerImg'] !== "" ? item['ownerImg'] : alternate} alt="" />

                      <div>
                        <h4>{item['item']['name']}</h4>

                        <p>{item['item']['symbol']}</p>
                      </div>
                    </div>

                    <button onClick={() => setInstance(item['item'])}>Mint</button>
                  </div>

                  <p>
                    {
                      item['item']['paymentToken'] == undefined || item['item']['paymentToken'] == "0x0000000000000000000000000000000000000000"
                        ?
                        <span>{item['item']['price']} XDC</span>
                        :
                        <span>{item['item']['price']} {item['tokenSymbol']}</span>
                    }
                  </p>
                  <div className='info mt-3'>
                    <Link to={`/project-detail/${item?.['item']?.['_id']}`}>
                      <button>View Details</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <Modal
        open={microeconomyToolModal}
        onClose={() => setMicroeconomyToolModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyToolModal(false)}
          />
        </button>

        <div className="modal-body">
          <img src={explorenft} alt="" />

          <h3>Explore NFT Collections</h3>

          <p>Discover, buy, sell, and trade various assets on our secure marketplace. From CIFI tokens to NFT collections and user-created tokens, explore a world of opportunities with Circularity Finance.</p>
        </div>
      </Modal>

      <Modal
        open={microeconomyMintModal}
        onClose={() => setMicroeconomyMintModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal mint-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyMintModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <form onSubmit={mintNFT}>
              <div className="form-head">
                {
                  data['paymentToken'] == undefined || data['paymentToken'] == "0x0000000000000000000000000000000000000000"
                    ?
                    <h3>Public Sale : {price} XDC</h3>
                    :
                    <h3>Public Sale : {price} Token</h3>

                }

                <p><span>{minted}</span> of <span>{data['supply']}</span></p>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  name='walletAddress'
                  value={props.publicAddress}
                  disabled
                />
              </div>

              <div className="form-group total-number">
                <div className="number">{count}</div>

                <div className="count-wrap">
                  <span onClick={incrementCount}>+</span>
                  <span onClick={decrementCount}>--</span>
                </div>
              </div>

              <button type="submit">
                Mint
              </button>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        open={microeconomySuccessModal}
        onClose={() => setMicroeconomySuccessModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal success-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomySuccessModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <img src={tickcircle} alt="Tick Circle" />

            <h3>Success</h3>

            <span>NFT Minted</span>
            <form>


              <div className="form-group hash">
                <p>
                  <CopyToClipboard
                    text={txHash}
                    onCopy={copiedAddress}
                  >
                    <a>{`Transaction Hash: ${txHash}`}</a>
                  </CopyToClipboard>
                  <img className="copy" src={copy} alt="Tick Circle" onClick={() => handleCopyText()} />
                </p>

                {networkId == 137 ? (
                  <a href={`https://polygonscan.com/tx/k${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 80001 ? (
                  <a href={`https://mumbai.polygonscan.com/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 56 ? (
                  <a href={`https://bscscan.com/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 97 ? (
                  <a href={`https://testnet.bscscan.com/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 50 ? (
                  <a href={`https://xdc.blocksscan.io/txs/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 51 ? (
                  <a href={`https://apothem.blocksscan.io/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal1}
        onClose={() => setStepModal1(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal1(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <h3>Step 1/2</h3>

            <i>Mint:</i>

            <p>Click <span>Mint</span> button from any collection card to mint that collection.</p>
          </div>


          <img src={mintstepinfo} alt="" />

          <div className="steps-btns">
            <button disabled>Previous step</button>

            <button onClick={() => { setStepModal2(true); setStepModal1(false) }}>Next step</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal2}
        onClose={() => setStepModal2(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal2(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <h3>Step 2/2</h3>

            <i>NFT collection details:</i>

            <p>Click <span>image</span> of a collection to view collection details.</p>
          </div>


          <img src={collectionimagestepinfo} alt="" />

          <div className="steps-btns">
            <button onClick={() => { setStepModal1(true); setStepModal2(false) }}>Previous step</button>

            <button disabled>Next step</button>
          </div>
        </div>
      </Modal>
    </div >
  );
}

const mapDispatchToProps = {
  setLoader,
  getXRC721,
  getXRC20,
  setBalance,
  setGrootBalance,
  getKybStatus,
  getKycStatus
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, xrc20Collections, xrc721Collections, selectDefaultNetwok, currentMode, isLoader, kycStatus, userSoulBound } = Auth;
  return { publicAddress, xrc20Collections, xrc721Collections, selectDefaultNetwok, currentMode, isLoader, kycStatus, userSoulBound }
};

export default connect(mapStateToProps, mapDispatchToProps)(Nftdrops);