import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { setExchangeGraph } from "../actions/Events";
import { all, takeEvery, call, put } from 'redux-saga/effects';
import {
  setAddress, setLoginToken, setToken, getOrderBook, setNonce, set24HVolume, getTradeOrders, setAllUserTrades, setAllUserOrders,
  setKycStatus, setKyc, setGKycStatus, kycResponse, setXRC20, setXRC721, setStaking, setDAO, setProposals, setCollection, setSoulBound,
  setSingelNft, setUriData, setStakedNFT, setVesting, setILO, setLoader, setKybStatus, setICO, setCiFiDAO, setCiFiProposals,
  setLPStaking, setStakedLP, setProjectDetails, clearProjectDetails, setXdcPrice
} from '../actions/Auth';

/* =================================== LOGIN FUNCTIONS =================================== */

function* login({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v1/signature", payload });
  const decoded = jwt_decode(response["data"]["body"]["token"]);
  if (error) {
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response && decoded) {
    yield put(setToken(response["data"]["body"]["token"]));
    yield put(setAddress(decoded['publicAddress']));
    yield put(setLoginToken(decoded['publicAddress']));
    EventBus.publish("success", response['data']['message'])
  }
};

/* =================================== KYC/KYB FUNCTIONS =================================== */

export function* getKycStatus({ payload }) {
  // Bypass the backend check and directly set the status
  yield put(setKycStatus("adminApproved"));
  yield put(setLoader({ status: false }));
};

export function* getKybStatus({ payload }) {
  // Bypass the backend check and directly set the status
  yield put(setKybStatus("approved"));
  yield put(setLoader({ status: false }));
};

export function* kycSubmit({ payload }) {
  // Bypass the submission process and directly set the status<
  yield put(setKycStatus("adminApproved"));
  yield put(setLoader({ status: false }));
};

export function* kybSubmit({ payload }) {
  // Bypass the submission process and directly set the status
  yield put(setKybStatus("approved"));
  yield put(setLoader({ status: false }));
};



function* getNonce({ payload }) {
  const { error, response } = yield call(getCall, `/v1/getNonce/${payload}`);
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setNonce(response["data"]["body"]));
  }
};

function* setOrderBook({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v1/buyOrder", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(getOrderBook(response["data"]["body"]));
  }
};

function* setBuyMarketOrders({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v1/marketBuyOrders", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(getOrderBook(response["data"]["body"]));
  }
};

function* getLimitOrderBook({ payload }) {
  const { error, response } = yield call(getCall, `/v1/getBuyOrders/${payload['pairs']}`);
  if (response) {
    yield put(getOrderBook(response["data"]["body"]));
  }
};

function* getUserOrders({ payload }) {
  const { error, response } = yield call(getCall, `/v1/getTradeOrders/${payload['publicAddress']}/${payload['pairs']}`);
  if (response) {
    // yield put(setUserOrders(response["data"]["body"]));
  }
};

function* get24HVolume({ payload }) {
  const { error, response } = yield call(getCall, `/v1/get24Hours/${payload['pairs']}/${payload['networkId']}`);
  if (response) {
    yield put(set24HVolume(response["data"]["body"]));
  }
}

function* getExchangeGraph({ payload }) {
  const { error, response } = yield call(getCall, `/getGraphData/${payload['pairs']}/${payload['networkId']}`);
  if (response) yield put(setExchangeGraph(response["data"]["body"]));
};

function* setTradeOrder({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v1/setTrade", payload });
  if (error) {
    yield put(getTradeOrders([]));
  }
  else if (response) {
    yield put(getTradeOrders(response["data"]["body"]));
  }
};

function* cancelTradeOrders({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v1/cancelTrades", payload });
  if (error) {
    yield put(getTradeOrders([]));
  }
  else if (response) {
    yield put(getTradeOrders(response["data"]["body"]));
  }
};

function* getSingleTradeOrders({ payload }) {
  const { error, response } = yield call(getCall, `/v1/getTrade/${payload['publicAddress']}/${payload['pairs']}/${payload['networkId']}`);
  if (error) {
    yield put(getTradeOrders([]));
  }
  else if (response) {
    yield put(getTradeOrders(response["data"]["body"]));
  }
};


function* cancelLimitOrderTrade({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v1/cancelLimitTrades", payload });
  if (response) {
    // yield put(setUserOrders(response["data"]["body"]));
  }
};

function* getAllUsersTrades({ payload }) {
  const { error, response } = yield call(getCall, `/v1/getAllUserTraids/${payload['publicAddress']}`);
  if (error) {
    yield put(setAllUserTrades([]));
  }
  else if (response) {
    yield put(setAllUserTrades(response["data"]["body"]));
  }
};

function* getAllUsersOreders({ payload }) {
  const { error, response } = yield call(getCall, `/v1/getAllUserOrders/${payload['publicAddress']}`);
  if (error) {
    yield put(setAllUserOrders([]));
  }
  else if (response) {
    yield put(setAllUserOrders(response["data"]["body"]));
  }
};


/* =================================== MICRO ECONOMY =================================== */

export function* uploadCollection({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/deployToken", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

export function* uploadStake({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/deployStaking", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

export function* getStaking() {
  const { error, response } = yield call(getCall, "/v3/getStaking");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setStaking(response['data']['body']));
  }
};

export function* uploadLPStake({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/deployLPStaking", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

export function* getLPStaking() {
  const { error, response } = yield call(getCall, "/v3/getLPStaking");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setLPStaking(response['data']['body']));
  }
};

export function* getXRC20() {
  const { error, response } = yield call(getCall, "/v3/getXRC20");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setXRC20(response['data']['body']));
  }
};

export function* getICO({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/getICO", payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    console.log("*** response :: ", response['data']['body']);
    yield put(setICO(response['data']['body']?.['ico']));
  }
};

export function* getXRC721() {
  const { error, response } = yield call(getCall, "/v3/getXRC721");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setXRC721(response['data']['body']));
  }
};

export function* getCollection({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/getCollection", payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setCollection(response['data']['body']));
  }
};

export function* stakeNFT({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/stakeNFT", payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put({
      type: "GET_STAKED_NFT",
      payload: {
        stakeAddress: payload['stakeAddress'],
        staker: payload['staker']
      }
    });
  }
};

export function* unStakeNFT({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/unStakeNFT", payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put({
      type: "GET_STAKED_NFT",
      payload: {
        stakeAddress: payload['stakeAddress'],
        staker: payload['staker']
      }
    });
  }
};

export function* getStakedNFT({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/getStakedNFT", payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setStakedNFT(response['data']['body']));
  }
};

export function* stakeLP({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/stakeLP", payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put({
      type: "GET_STAKED_LP",
      payload: {
        stakeAddress: payload['stakeAddress'],
        staker: payload['staker']
      }
    });
  }
};

export function* unStakeLP({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/unStakeLP", payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put({
      type: "GET_STAKED_LP",
      payload: {
        stakeAddress: payload['stakeAddress'],
        staker: payload['staker']
      }
    });
  }
};

export function* getStakedLP({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/getStakedLP", payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setStakedLP(response['data']['body']));
  }
};

export function* deployVestings({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/deployVesting", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

export function* getVesting() {
  const { error, response } = yield call(getCall, "/v3/getVesting");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setVesting(response['data']['body']));
  }
};

export function* deployILO({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/deployILO", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(setILO(response['data']['body']));
    EventBus.publish("success", response['data']['message'])
  };
};

export function* getILO() {
  const { error, response } = yield call(getCall, "/v3/getILO");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setILO(response['data']['body']));
  }
};

export function* updateILOTime({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/updateILOTime", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(setILO(response['data']['body']));
    EventBus.publish("success", response['data']['message'])
  };
};

export function* update721Description({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/update721Description", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader({ status: false }));
  }
  else if (response) {
    yield put(setCollection(response['data']['body']));
    EventBus.publish("success", response['data']['message'])
  };
};

export function* updateUserImage({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v1/updateUserImage", payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader({ status: false }));
  }
  else if (response) {
    yield put(setKyc(response['data']['body']));
    EventBus.publish("success", response['data']['message'])
  };
};

export function* getProjectDetails({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/getProjectDetails", payload });
  if (error) {
    // EventBus.publish("error", error['response']['data']['message']);
    yield put(setProjectDetails({}));
    yield put(setLoader({ status: false }));
  }
  else if (response) {
    yield put(setProjectDetails(response['data']['body']));
    // EventBus.publish("success", response['data']['message'])
    yield put(setLoader({ status: false }));
  };
};

export function* addProjectDetails({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/addProjectDetails", payload });
  console.log("*** error :: ", error);
  console.log("*** response :: ", response);
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(setLoader({ status: false }));
  }
  else if (response) {
    yield put(clearProjectDetails(true));
    EventBus.publish("success", response['data']['message'])
    yield put(setLoader({ status: false }));
  };
};


/* =================================== DAO =================================== */

export function* getDAO() {
  const { error, response } = yield call(getCall, "/v2/getDAO");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setDAO(response['data']['body']));
  }
};

export function* getProposals({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v2/getProposals", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(setProposals(response['data']['body']));
  }
};

export function* deployDAO({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v2/deployDAO", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

export function* addProposal({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v2/addProposal", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

export function* sendEmailDao({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v2/sendEmail", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

export function* getCiFiDAO() {
  const { error, response } = yield call(getCall, "/v2/getCifiDAO");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setCiFiDAO(response['data']['body']));
  }
};

export function* getCiFiProposals({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v2/getCifiProposals", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(setCiFiProposals(response['data']['body']));
  }
};

export function* addCiFiProposal({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v2/addCifiProposal", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

export function* sendEmailCIFIDao({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v2/sendEmailCiFi", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};


/* =================================== MARKETPLACE =================================== */

export function* getSingelNft({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/getSingelNft", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(setSingelNft(response['data']['body']));
  }
};

export function* createMarketSale({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/createMarketSale", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(setSingelNft(response['data']['body']));
  }
};

export function* createMarketItem({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/createMarketItem", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({
      type: "GET_SINGLE_NFT",
      payload: {
        publicAddress: payload['publicAddress'],
        TokenAddress: payload['TokenAddress'],
        nftId: payload['nftId']
      }
    });
    EventBus.publish("success", response['data']['message'])
  };
};

export function* unListItem({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/unListItem", payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put({
      type: "GET_SINGLE_NFT",
      payload: {
        TokenAddress: payload['TokenAddress'],
        nftId: payload['nftId']
      }
    });
  }
};

export function* getUriData({ payload }) {
  const { error, response } = yield call(postCall, { path: "/v3/getUriData", payload });
  if (response) {
    yield put(setUriData(response['data']['body']));
  }
};

export function* getXdcPrice() {
  const { error, response } = yield call(getCall, "/v1/getXdcPrice");
  if (response) {
    yield put(setXdcPrice(parseFloat(response['data']['body'][0]['price'])));
  }
};

/*========== KYC FUNCTIONS =============*/

// export function* getKycStatus({ payload }) {
//   const { error, response } = yield call(postCall, { path: '/v1/getKycStatus', payload });
//   if (error) {
//     localStorage.setItem("kycStatus", "nill");
//     yield put(setLoader({ status: false }));
//     // EventBus.publish("error", error['response']['data']['message']);
//   }
//   else if (response) {
//     localStorage.setItem("kycStatus", response['data']['body']['kycStatus']);
//     yield put(setKyc(response['data']['body']));
//     yield put(setKycStatus(response['data']['body']['status']));
//     yield put(setSoulBound(response['data']['body']['soulbound']));
//     yield put(setLoader({ status: false }));
//   }
// };

export function* kycGStatus({ payload }) {
  const { error, response } = yield call(postCall, { path: '/v1/kycGStatus', payload });
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setKyc(response['data']['body']));
    yield put(setGKycStatus(response['data']['body'][0]['status']));
  }
};

// export function* kycSubmit({ payload }) {
//   const { error, response } = yield call(postCall, { path: '/v1/kycSubmit', payload });
//   if (error) {
//     EventBus.publish("error", error['response']['data']['message']);
//   } else if (response) {
//     yield put(kycResponse(response['data']['body']));
//   }
//   yield put(setLoader({ status: false }));
// };


// export function* kybSubmit({ payload }) {
//   const { error, response } = yield call(postCall, { path: '/v1/kybSubmit', payload });
//   if (error) {
//     EventBus.publish("error", error['response']['data']['message']);
//   } else if (response) {
//     yield put(setKybStatus(response['data']['body']['kybStatus']));
//   }
//   yield put(setLoader({ status: false }));
// };


// export function* getKybStatus({ payload }) {
//   const { error, response } = yield call(postCall, { path: '/v1/getKybStatus', payload });
//   if (error) {
//     localStorage.setItem("kybStatus", "nill");
//     yield put(setLoader({ status: false }));
//     // EventBus.publish("error", error['response']['data']['message']);
//   }
//   else if (response) {
//     localStorage.setItem("kybStatus", response['data']['body']['kybStatus']);
//     yield put(setKybStatus(response['data']['body']['kybStatus']));
//     yield put(setLoader({ status: false }));
//   }
// };
/* =================================== OTHER SAGAS =================================== */

// ... (keep all other sagas unchanged)

function* actionWatcher() {
  yield takeEvery('LOGIN', login);
  yield takeEvery('SET_ORDERBOOK', setOrderBook);
  yield takeEvery('GET_LIMIT_ORDERBOOK', getLimitOrderBook)
  yield takeEvery('GET_USER_ORDERS', getUserOrders)
  yield takeEvery('GET_NONCE', getNonce)
  yield takeEvery('GET_24_HOURS_VOLUME', get24HVolume)
  yield takeEvery('SET_BUY_MARKET_ORDERS', setBuyMarketOrders)
  yield takeEvery('GET_EXCHANGE_GRAPH', getExchangeGraph)
  yield takeEvery('SET_TRADE_ORDERS', setTradeOrder)
  yield takeEvery('CANCEL_TRADE_ORDERS', cancelTradeOrders)
  yield takeEvery('GET_SINGLE_TRADE_ORDERS', getSingleTradeOrders)
  yield takeEvery('CANCEL_LIMIT_ORDER_TRADES', cancelLimitOrderTrade)
  yield takeEvery('GET_ALL_USER_TRADES', getAllUsersTrades)
  yield takeEvery('GET_ALL_USER_ORDERS', getAllUsersOreders)

  yield takeEvery('UPLOAD_COLLECTION', uploadCollection)
  yield takeEvery('UPLOAD_STAKE', uploadStake)
  yield takeEvery('GET_STAKING', getStaking)
  yield takeEvery('UPLOAD_LP_STAKE', uploadLPStake)
  yield takeEvery('GET_LP_STAKING', getLPStaking)
  yield takeEvery('GET_XRC20', getXRC20)
  yield takeEvery('GET_ICO', getICO)
  yield takeEvery('GET_XRC721', getXRC721)
  yield takeEvery('GET_COLLECTION', getCollection)
  yield takeEvery('STAKE_NFT', stakeNFT)
  yield takeEvery('UNSTAKE_NFT', unStakeNFT)
  yield takeEvery('GET_STAKED_NFT', getStakedNFT)
  yield takeEvery('STAKE_LP', stakeLP)
  yield takeEvery('UNSTAKE_LP', unStakeLP)
  yield takeEvery('GET_STAKED_LP', getStakedLP)
  yield takeEvery('DEPLOY_VESTINGS', deployVestings)
  yield takeEvery('GET_VESTING', getVesting)
  yield takeEvery('DEPLOY_ILO', deployILO)
  yield takeEvery('UPDATE_ILO_TIME', updateILOTime)
  yield takeEvery('GET_ILO', getILO)
  yield takeEvery('UPDATE_721_DESCRIPTION', update721Description)
  yield takeEvery('UPDATE_USER_IMAGE', updateUserImage)
  yield takeEvery('ADD_PROJECT_DETAILS', addProjectDetails)
  yield takeEvery('GET_PROJECT_DETAILS', getProjectDetails)
  yield takeEvery('GET_XDC_PRICE', getXdcPrice)

  yield takeEvery('GET_DAO', getDAO)
  yield takeEvery('GET_PROPOSALS', getProposals)
  yield takeEvery('DEPLOY_DAO', deployDAO)
  yield takeEvery('ADD_PROPOSAL', addProposal)
  yield takeEvery('SEND_EMAIL_DAO', sendEmailDao)

  yield takeEvery('GET_CIFI_DAO', getCiFiDAO)
  yield takeEvery('ADD_CIFI_PROPOSAL', addCiFiProposal)
  yield takeEvery('GET_CIFI_PROPOSALS', getCiFiProposals)
  yield takeEvery('SEND_EMAIL_CIFI_DAO', sendEmailCIFIDao)

  yield takeEvery('UNLIST_ITEM', unListItem)
  yield takeEvery('GET_URI_DATA', getUriData)
  yield takeEvery('GET_SINGLE_NFT', getSingelNft)
  yield takeEvery('CREATE_MARKET_ITEM', createMarketItem)
  yield takeEvery('CREATE_MARKET_SALE', createMarketSale)

  yield takeEvery('GET_KYC_STATUS', getKycStatus);
  yield takeEvery('KYC_G_STATUS', kycGStatus);
  yield takeEvery('KYC_SUBMIT', kycSubmit);

  yield takeEvery('KYB_SUBMIT', kybSubmit);
  yield takeEvery('GET_KYB_STATUS', getKybStatus);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};