import Prompt from "../use-prompt";
import { Veriff } from '@veriff/js-sdk';
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Form, Button } from 'react-bootstrap';
import { Modal } from "react-responsive-modal";
import goarrow from "../../images/go-arrow.png";
import modalcloseicon from "../../images/close.png";
import uploadicon from "../../images/uploadicon.png";
import infocircle from "../../images/infocircle.svg";
import deploytoken from "../../images/deploytoken.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import microeconomylogo from "../../images/microeconomy-icon.png";
import tokengenerationstepinfo from "../../images/token-generation-step-info.png";
import createnftstepinfo from "../../images/create-nft-step-info.png";
import deployvestingstepinfo from "../../images/deploy-vesting-step-info.png";
import { uploadCollection, setBalance, setGrootBalance, kycSubmit, getKycStatus, setLoader, kybSubmit, getKybStatus } from "../../store/actions/Auth";
import { CiFiAccessNFTAddress, CiFiAccessNFTContract, CiFiAccessNFTABI, AdminWhitelist, AdminWhitelistAddress, AdminWhitelistABI } from "../../store/config";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

const Verification = (props) => {

  const [isNFT, setIsNFT] = useState();
  const [isWhitelist, setIsWhitelist] = useState(false);
  const ownerSocialNoRef = useRef(null);
  const businessIdRef = useRef(null);
  const businessEmailRef = useRef(null);
  const businessTitleRef = useRef(null);
  const securityNumberRef = useRef(null);
  const websiteRef = useRef(null);
  const facebookRef = useRef(null);
  const linkedinRef = useRef(null);
  const twitterRef = useRef(null);
  const businessEINRef = useRef(null);
  const businessDocumentRef = useRef(null);

  const navigate = useNavigate();

  setTimeout(() => {
    const modalRoot = document.querySelectorAll('.react-responsive-modal-root');
    if (modalRoot) {
      //Add a custom class to the root element

      modalRoot.forEach((element) => {
        const stepsModal = element.querySelector('.steps-modal');
        const confirmation1Modal = element.querySelector('.confirmation-modal');

        if (stepsModal) {
          // Perform your action here
          element.classList.add('custom-modal-root');
        }

        if (confirmation1Modal) {
          // Perform your action here
          element.classList.add('confirmation-modal-root');
        }
      });

    }
    else {
      console.error("Element not found.");
    }

  }, 1000);

  useEffect(() => {
    if (props.publicAddress) {
      // Automatically set KYC and KYB statuses
      props.kycSubmit({ status: "adminApproved" });
      props.kybSubmit({ status: "approved" });
    }
  }, [props.publicAddress]);

  const getNFT = async () => {
    props.setLoader({ message: "Load Microeconomy...", status: true });
    props.getKybStatus({ publicAddress: props.publicAddress });
    props.getKycStatus({ publicAddress: props.publicAddress });
  }

  useEffect(() => {
    if (props.publicAddress && web3) {
      getNFT();
    }
  }, [props.publicAddress, web3]);

  const submitKYBForm = (e) => {
    e.preventDefault();

    // Retrieve values using refs
    const businessEmail = businessEmailRef.current.value;
    const businessTitle = businessTitleRef.current.value;
    const securityNumber = securityNumberRef.current.value;
    const website = websiteRef.current.value;
    const facebook = facebookRef.current.value;
    const linkedin = linkedinRef.current.value;
    const twitter = twitterRef.current.value;
    const ein = businessEINRef.current.value
    const businessDocument = businessDocumentRef.current.files[0];

    if (!businessEmail.trim()) return EventBus.publish("error", "Please enter your business email");
    if (!businessTitle.trim()) return EventBus.publish("error", "Please enter your business title");
    if (!ein.trim()) return EventBus.publish("error", "Please enter your business EIN number");
    if (!securityNumber.trim()) return EventBus.publish("error", "Please enter your security number");
    if (!businessDocument) return EventBus.publish("error", "Please submit you business document in PDF formate");
    if (!businessDocument.type.includes("pdf")) return EventBus.publish("error", "Please submit you business document in PDF formate");

    props.setLoader({ message: "KYB Submitting...", status: true });
    const formData = new FormData();
    formData.append('document', businessDocument);
    formData.append('email', businessEmail);
    formData.append('publicAddress', props.publicAddress);
    formData.append('ein', ein);
    formData.append('title', businessTitle);
    formData.append('ownerSocialNo', securityNumber);
    formData.append('website', website);
    formData.append('facebook', facebook);
    formData.append('linkedin', linkedin);
    formData.append('twitter', twitter);
    props.kybSubmit(formData)
  }

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={microeconomylogo} alt="" />
          <span>Micro Economy</span>
        </a>
        <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy CIFI NFTs</a>
        <ConnectWallet />
      </div>
      {props.publicAddress ?
        <div className="form-wrap min">
          <div className="inner">
            <div className="kyc-kyb-tabs">
              <ul className="nav nav-tabs" id="tab1" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="kyc-tab" data-toggle="tab" href="#kyc" role="tab" aria-controls="kyc" aria-selected="true">KYC</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="kyb-tab" data-toggle="tab" href="#kyb" role="tab" aria-controls="kyb" aria-selected="false">KYB</a>
                </li>
              </ul>
              <div className="tab-pane fade show active" id="kyc" role="tabpanel" aria-labelledby="kyc-tab">
                <p className="text-center">Your KYC is approved</p>
              </div>
              <div className="tab-pane fade" id="kyb" role="tabpanel" aria-labelledby="kyb-tab">
                <p className="text-center">Your KYB is approved</p>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="form-wrap min">
          <div className="inner">
            <h2>Wallet Not Connected</h2>
          </div>
        </div>
      }
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  uploadCollection,
  setBalance,
  setGrootBalance,
  kycSubmit,
  getKycStatus,
  getKybStatus,
  kybSubmit
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, currentMode, isLoader, kycStatus, kycValue, kycSubmitted, kycGStatus, kybStatus } = Auth;
  return { publicAddress, currentMode, isLoader, kycStatus, kycValue, kycSubmitted, kycGStatus, kybStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(Verification);